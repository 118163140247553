import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { Grid } from '@fuww/library/src/Grid';
import Headline5 from '@fuww/library/src/Typography/Heading/Headline5';
import { useIntl } from 'react-intl';
import utc from 'dayjs/plugin/utc';
import jobsCountQuery from '../../lib/queries/dashboard/jobsCountQuery.graphql';
import allJobsPageviewsCount
  from '../../lib/queries/dashboard/allJobsPageviewsCount.graphql';
import allJobsApplyButtonClicksCount
  from '../../lib/queries/dashboard/allJobsApplyButtonClicksCount.graphql';
import transactionsCount
  from '../../lib/queries/dashboard/transactionsCount.graphql';
import monthlyApplicantsCount
  from '../../lib/queries/dashboard/monthlyApplicantsCount.graphql';
import DashboardChart from '../StatisticChart';
import applicantsCountQuery
  from '../../lib/queries/dashboard/applicantsCountQuery.graphql';
import messages from '../../lib/messages.mjs';
import monthlyTransactionsCount
  from '../../lib/queries/dashboard/monthlyTransactionsCount.graphql';
import monthlyAllJobsApplyButtonClicksCount
  // eslint-disable-next-line max-len
  from '../../lib/queries/dashboard/monthlyAllJobsApplyButtonClicksCount.graphql';
import monthlyAllJobsPageviewsCount
  from '../../lib/queries/dashboard/monthlyAllJobsPageviewsCount.graphql';
import { amountOfDays, jobPublicationsProductIds, repostsProductIds }
  from '../../lib/constants';
import StatisticBox from '../StatisticBox';
import subtractDays from '../../lib/subtractDays';
import getDateRange from '../../lib/getDateRange';
import formatPeriodEndpoint from '../../lib/formatPeriodEndpoint';
import { hasGlobalRole, hasRoleInAnyGroup } from '../../lib/hasUserRoles';
import { UserContext } from '../UserProvider';

dayjs.extend(utc);

const queryToVariablesMap: Record<string, {
  periodEndPointSuffix?: string;
  format: 'date' | 'dateTime';
}> = {
  jobsCountQuery: {
    format: 'dateTime',
  },
  allJobsPageviewsCount: {
    periodEndPointSuffix: 'minEventInsertedAt',
    format: 'date',
  },
  allJobsApplyButtonClicksCount: {
    periodEndPointSuffix: 'minEventInsertedAt',
    format: 'date',
  },
  transactionsCount: {
    periodEndPointSuffix: 'minInsertedAt',
    format: 'dateTime',
  },
  applicantsCount: {
    periodEndPointSuffix: 'minInsertedAt',
    format: 'dateTime',
  },
  monthlyApplicantsCount: {
    periodEndPointSuffix: 'InsertedAt',
    format: 'dateTime',
  },
  monthlyTransactionsCount: {
    periodEndPointSuffix: 'InsertedAt',
    format: 'dateTime',
  },
  monthlyAllJobsApplyButtonClicksCount: {
    periodEndPointSuffix: 'EventInsertedAt',
    format: 'date',
  },
  monthlyAllJobsPageviewsCount: {
    periodEndPointSuffix: 'EventInsertedAt',
    format: 'date',
  },
};

const getStatisticBoxVariables = (
  queryName: string, additionalVariables?: Record<string, unknown>,
) => {
  const date = dayjs(subtractDays(amountOfDays));
  const { format, periodEndPointSuffix } = queryToVariablesMap[queryName];

  const dateVariables = periodEndPointSuffix
    ? { [periodEndPointSuffix]: formatPeriodEndpoint(date, format) }
    : {};

  return { ...dateVariables, ...additionalVariables };
};

const getStatisticChartVariables = (
  queryName: string, additionalVariables?: Record<string, unknown>,
) => {
  const { min, max } = getDateRange();
  const { periodEndPointSuffix, format } = queryToVariablesMap[queryName];

  return {
    [`min${periodEndPointSuffix}`]: formatPeriodEndpoint(min, format),
    [`max${periodEndPointSuffix}`]: formatPeriodEndpoint(max, format),
    ...additionalVariables,
  };
};

const DashboardStatistics = () => {
  const intl = useIntl();
  const { roles } = useContext(UserContext);

  const hasGlobalJobsRole = roles && hasGlobalRole(
    roles, 'jobs',
  );

  const hasJobStatisticsRole = roles && hasRoleInAnyGroup(
    roles, 'jobStatistics',
  );

  const hasTransactionsRole = roles && hasRoleInAnyGroup(
    roles, 'transactions',
  );

  const hasApplicantsRole = roles && hasRoleInAnyGroup(
    roles, 'applicants',
  );

  return (
    <>
      <Grid>
        <>
          {hasJobStatisticsRole && (
            <StatisticBox
              fieldName="jobsCount"
              label="Active jobs"
              query={jobsCountQuery}
              variables={
                getStatisticBoxVariables('jobsCountQuery', {
                  scoped: !hasGlobalJobsRole,
                })
              }
              isCurrentData
            />
          )}
          {hasJobStatisticsRole && (
            <>
              <StatisticBox
                fieldName="allJobsPageviewsCount"
                label="Views"
                query={allJobsPageviewsCount}
                variables={getStatisticBoxVariables('allJobsPageviewsCount')}
              />
              <StatisticBox
                fieldName="allJobsApplyButtonClicksCount"
                label="Apply button clicks"
                query={allJobsApplyButtonClicksCount}
                variables={getStatisticBoxVariables(
                  'allJobsApplyButtonClicksCount',
                )}
              />
              {hasApplicantsRole && (
              <StatisticBox
                fieldName="applicantsCount"
                label="Applicants"
                query={applicantsCountQuery}
                variables={getStatisticBoxVariables('applicantsCount')}
              />
              )}
            </>
          )}
        </>

        {hasTransactionsRole && (
          <>
            <StatisticBox
              fieldName="transactionsCount"
              label="Reposts"
              query={transactionsCount}
              variables={getStatisticBoxVariables(
                'transactionsCount', { productIds: repostsProductIds },
              )}
            />
            <StatisticBox
              fieldName="transactionsCount"
              label="Job publications"
              query={transactionsCount}
              variables={getStatisticBoxVariables(
                'transactionsCount', { productIds: jobPublicationsProductIds },
              )}
            />
          </>
        )}
      </Grid>

      {(hasJobStatisticsRole || hasTransactionsRole) && (
        <Headline5 tag="h3">
          {intl.formatMessage(messages.last12MonthsStatistics)}
        </Headline5>
      )}
      <Grid>
        {hasApplicantsRole && (
          <DashboardChart
            query={monthlyApplicantsCount}
            variables={getStatisticChartVariables('monthlyApplicantsCount')}
            label="Applicants"
            fieldName="monthlyApplicantsCount"
          />
        )}
        {hasTransactionsRole && (
          <>
            <DashboardChart
              query={monthlyTransactionsCount}
              variables={getStatisticChartVariables(
                'monthlyTransactionsCount',
                { productIds: jobPublicationsProductIds },
              )}
              label="Publications"
              fieldName="monthlyTransactionsCount"
            />
            <DashboardChart
              query={monthlyTransactionsCount}
              variables={getStatisticChartVariables('monthlyTransactionsCount',
                { productIds: repostsProductIds })}
              label="Reposts"
              fieldName="monthlyTransactionsCount"
            />
          </>
        )}
        {hasJobStatisticsRole && (
          <>
            <DashboardChart
              query={monthlyAllJobsApplyButtonClicksCount}
              variables={getStatisticChartVariables(
                'monthlyAllJobsApplyButtonClicksCount',
              )}
              label="Apply button clicks"
              fieldName="monthlyAllJobsApplyButtonClicksCount"
            />
            <DashboardChart
              query={monthlyAllJobsPageviewsCount}
              variables={getStatisticChartVariables(
                'monthlyAllJobsPageviewsCount',
              )}
              label="Page views"
              fieldName="monthlyAllJobsPageviewsCount"
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default DashboardStatistics;
